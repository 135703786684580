import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Link } from "react-router-dom";

import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <FacebookIcon />
      </ListItemIcon>
      <Link to="/platforms/meta">Meta marketing</Link>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <GoogleIcon />
      </ListItemIcon>
      <Link to="/platforms/google">Google Ads (p)</Link>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <TwitterIcon />
      </ListItemIcon>
      <Link to="/platforms/twitter">Twitter (p)</Link>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <FontDownloadIcon />
      </ListItemIcon>
      <Link to="/platforms/snapchat">Snapchat (p)</Link>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <FontDownloadIcon />
      </ListItemIcon>
      <Link to="/platforms/tiktok">Tiktok (p)</Link>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <LinkedInIcon />
      </ListItemIcon>
      <Link to="/platforms/linkedin">LinkedIn (p)</Link>
    </ListItemButton>
    {/* <ListItemButton>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton> */}
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton> */}
  </React.Fragment>
);
