import "./App.css";
import Carddata from "./DashboardComponents/Components/Cards/CardItem";
import DataRowImg from "./DashboardComponents/Components/DataRowImg";
import DataRowItem from "./DashboardComponents/Components/DataRowItem";
import { getPlatformImage } from "./DashboardComponents/Assets/Assets";
import { useParams } from "react-router-dom";
import pusher from "../../utils/pusher";
import env from "react-dotenv";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function SummaryCard() {
  // Listen for ad data pushes
  const appName = env.REACT_APP_NAME;
  const { clientId } = useParams();
  const channelName = `${appName}_${clientId}_updates`;
  const [summaryData, setSummaryData] = useState({
    impressions: 0,
    clicks: 0,
    cpc: 0,
    cost: 0,
    cpa: 0
  });

  const reSearchStatus = useSelector(
    (store) => store.common.account_metric_filters.re_search
  );

  useEffect(() => {
    setSummaryData({
      impressions: 0,
      clicks: 0,
      cpc: 0,
      cost: 0,
      cpa: 0
    });
  }, [reSearchStatus]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  const listenForDataUpdates = () => {
    const pusherChannel = pusher.subscribe(channelName);

    //listen for data payload
    pusherChannel.bind(`summary_data_payload`, function (data) {
      // console.log(data);
      setSummaryData(data);
      setIsLoading(false);
    });

    //listen for data status change
    pusherChannel.bind(`summary_data_status`, function (data) {
      setIsLoading(false);
      if (data.status == "failed") {
        setError(`Loading `);
      }
    });
  };

  useEffect(() => {
    listenForDataUpdates();
    return () => {
      pusher.unsubscribe(channelName);
    };
  }, []);

  return (
    <div className="App">
      <div className="box-container">
        <div className="box-wrap">
          <div className="card_item">
            <Carddata
              status={"success"}
              cardTitle="Impressions"
              cardSubtitle={summaryData?.impressions}
              cardDescription=""
            />
          </div>
          <div className="card_item">
            <Carddata
              status={"success"}
              cardTitle="Clicks"
              cardSubtitle={summaryData?.clicks}
              cardDescription=" "
            />
          </div>
          <div className="card_item">
            <Carddata
              status={"danger"}
              cardTitle="CPC"
              cardSubtitle={summaryData?.cpc}
              cardDescription=""
            />
          </div>
          <div className="card_item">
            <Carddata
              status={"success"}
              cardTitle="Cost"
              cardSubtitle={summaryData?.cost}
              cardDescription=" "
            />
          </div>
          <div className="card_item">
            <Carddata
              status={"danger"}
              cardTitle="CPA"
              cardSubtitle={summaryData?.cpa}
              cardDescription=" "
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryCard;
