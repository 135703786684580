import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import * as React from "react";
import { useDispatch } from "react-redux";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { changeAccountDateRangeFilter } from "../../../store/reducers/common/CommonReducer";

export default function CustomDateRangePicker(props) {
  const dispatch = useDispatch();
  const handleSelect = (ranges) => {
    console.log(ranges);
    const selectedDates = ranges?.selection;
    let { startDate, endDate } = selectedDates;
    startDate = moment(startDate, moment.ISO_8601).format("YYYY-MM-DD");
    endDate = moment(endDate, moment.ISO_8601).format("YYYY-MM-DD");
    dispatch(
      changeAccountDateRangeFilter({ date_range: { startDate, endDate } })
    );
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };

  const todaysDateObject = moment().toDate();
  const lastWeekDateObject = moment().subtract(7, "days").toDate();

  const selectionRange = {
    startDate: lastWeekDateObject,
    endDate: todaysDateObject,
    key: "selection"
  };
  return (
    <DateRangePicker
      ranges={[selectionRange]}
      onChange={handleSelect}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      calendarFocus={"backwards"}
      maxDate={new Date()}
      direction="horizontal"
      showDateDisplay={false}
      preventSnapRefocus={false}
    />
  );
}
