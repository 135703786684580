import env from "react-dotenv";
import Pusher from "pusher-js"

const appKey = env?.REACT_APP_PUSHER_KEY
const cluster = env?.REACT_APP_PUSHER_CLUSTER

const pusher = new Pusher(appKey, { cluster })

export default pusher;

