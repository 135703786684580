import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";

const initialState = {
  account_metric_filters: {
    re_search: false,
    platform: "all",
    date_range: "",
    is_filter_modal_active: false
  },
  selected_ad_accounts: {
    meta: "",
    google: "",
    linkedin: ""
  },
  platforms: {
    meta: {
      ad_accounts: {}
    },
    google: {
      ad_accounts: {}
    },
    linkedin: {
      ad_accounts: {}
    }
  },
  loading_statuses: {
    meta: false,
    linkedin: false,
    google: false,
    all: false
  }
};

const commonAppSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    changeAccountPlatformFilter(state, action) {
      const { payload } = action;
      state["account_metric_filters"]["platform"] = payload?.platform;
    },
    changeRefetchingStatus(state, action) {
      const { platform, status } = action?.payload;
      // if (platform == "all") {
      //   const availablePlaforms = state["loading_statuses"];
      //   for (let key of Object.keys(availablePlaforms)) {
      //     state["loading_statuses"][key] = status;
      //   }
      // } else {
      state["loading_statuses"][platform] = status;
      // }
    },
    changeAccountDateRangeFilter(state, action) {
      const { payload } = action;
      state["account_metric_filters"]["date_range"] = payload?.date_range;
    },
    changeReSearchFilter(state, action) {
      const { payload } = action;
      const { status } = payload;
      state["account_metric_filters"]["re_search"] = status;
    },
    changeSearchModalVisibility(state, action) {
      const currentModalState = action.payload;
      state["account_metric_filters"]["is_filter_modal_active"] =
        currentModalState;
    },
    changeSelectedAdAccount(state, action) {
      const { platform, ad_account_id } = action.payload;
      state["selected_ad_accounts"][platform] = ad_account_id;
    },
    setPlatformAdAccountSummaryMetrics(state, action) {
      const adAccountDetails = action.payload.ad_account_details;
      const adAccountId = action.payload.ad_account_id;
      const platform = action.payload.platform;
      _.set(
        state,
        "platforms." + platform + ".ad_accounts." + adAccountId,
        adAccountDetails
      );
    }
  }
});

export const {
  changeAccountPlatformFilter,
  changeAccountDateRangeFilter,
  changeSearchModalVisibility,
  changeReSearchFilter,
  changeSelectedAdAccount,
  changeRefetchingStatus,
  setPlatformAdAccountSummaryMetrics
} = commonAppSlice.actions;

export default commonAppSlice.reducer;
