function naiveRound(num, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}
//computes platform wise CPA calculation
const computeCpaForMeta = (conversionList, currentActionType, totalCost) => {
  let costPerAcquistion = 0;

  const cpaMetricIndex = conversionList.findIndex((conversionEntry) => {
    return conversionEntry.action_type == currentActionType;
  });

  let currentActionValue = conversionList[cpaMetricIndex]["value"];
  costPerAcquistion = totalCost / currentActionValue;
  costPerAcquistion = naiveRound(costPerAcquistion, 2);
  return costPerAcquistion;
};

module.exports = { naiveRound, computeCpaForMeta };
