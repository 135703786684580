import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import { useEffect, useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  addFacebookCampaigns,
  changeStatusFilter,
  changeLoadingStatus,
  sortBySpendAmount,
  setRefetchStatus,
  sortMetaCampaignsByRank
} from "../../../store/reducers/facebook/CampaignListReducer";
import Loading from "../../../assets/loading_blue.gif";
import small_loading from "../../../assets/loading_small.gif";
import { filterCampaignData, rankCampaigns } from "../helpers/MetaAdData";
import appAxios from "../../../utils/axios";
import { useParams } from "react-router-dom";
import group from "core-js/actual/array/group";

import { addGroupedMetaCampaigns } from "../../../store/reducers/facebook/MetaReducer";

function preventDefault(event) {
  event.preventDefault();
}

export default function Deposits() {
  const [adAccountMetrics, setAdAccountMetrics] = useState({});
  const [adAccountMetricsLoading, setadAccountMetricsLoading] = useState(false);
  const [selectedAdAccount, setSelectedAdAccount] = useState("");
  const [error, setError] = useState("");
  const [ownedAdAccounts, setOwnedAdAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");

  const { clientId, adAccountId } = useParams();

  //store
  const dispatch = useDispatch();

  //methods
  //get all ad accounts
  const metaAccessToken = process.env.REACT_APP_META_ACCESS_KEY;
  const getAdAccounts = async () => {
    const fetchAdAccounts = async () => {
      const apiUrl = `clients/${clientId}/meta/ad-accounts`;
      const ownedAdAccountsResponse = await appAxios.get(apiUrl);
      return ownedAdAccountsResponse?.data?.data;
    };

    return fetchAdAccounts();
  };

  //adAccount details
  const getAdAccountMetrics = async (selectedAdAccount) => {
    try {
      setadAccountMetricsLoading(true);
      setAdAccountMetrics("");
      setError("");
      let selectedAdAccountMetrics = ownedAdAccounts.find(
        (adAccount) => adAccount.id === selectedAdAccount
      );

      setadAccountMetricsLoading(false);
      setAdAccountMetrics(selectedAdAccountMetrics);
    } catch (err) {
      setadAccountMetricsLoading(false);
      console.log(err);
      setError("Cant get account metrics");
    }
  };

  let basicRows = [];
  const parseMetaResponse = (response) => {
    // datas.forEach((singleRowData) => {
    //   let impressions = 0;
    //   let spend_amount = 0;
    //   let reaches = 0;
    //   let currency = "";
    //   let insights = singleRowData?.insights?.data[0];

    //   if (singleRowData?.insights) {
    //     impressions = insights?.impressions;
    //     spend_amount = insights?.spend;
    //     reaches = insights?.reach;
    //     currency = insights?.account_currency;
    //   }
    //   singleRowData = {
    //     ...singleRowData,
    //     currency,
    //     impressions,
    //     spend_amount,
    //     reaches
    //   };
    //   fetchedRows.push(singleRowData);
    // });

    response.forEach((data) => {
      basicRows.push(data);
    });

    return basicRows;
  };

  const parseCampaignMetricsData = (datas) => {
    let parsedRows = [];
    datas.forEach((insights) => {
      let id = insights?.campaign_id;
      let name = insights?.campaign_name;
      let reach = insights?.reach;

      let { conversions, impressions, spend, clicks, cpc, ctr } = insights;

      parsedRows.push({
        cpc,
        ctr,
        name,
        clicks,
        impressions,
        spend,
        reach,
        conversions
      });
    });
    return parsedRows;
  };

  const getAdAccountCampaigns = async (adAccountId) => {
    dispatch(changeLoadingStatus({ type: "campaigns", status: true }));

    try {
      // const metaAccessToken = env.REACT_APP_META_ACCESS_KEY;
      // const fieldsExpression = `?fields=id,name,status,budget_remaining,start_time,insights{reach,impressions,account_currency,spend,ctr,cpc},end_time&limit=1000`;
      // let url = `https://graph.facebook.com/v15.0/${selectedAdAccount}/campaigns${fieldsExpression}`;
      // const response = await axios.get(url, {
      //   headers: { Authorization: `Bearer ${metaAccessToken}` }
      // });

      const allCampaignsUrl = `clients/${clientId}/meta/${adAccountId}/campaigns`;
      const getMetaCampaignsResponse = await appAxios.get(allCampaignsUrl);
      const allCampaigns = getMetaCampaignsResponse?.data?.data?.basic;
      const allCampaignMetrics = getMetaCampaignsResponse?.data?.data?.metrics;

      // start
      const allActiveCampaigns = allCampaigns.filter(
        (campaign) => campaign.status == "ACTIVE"
      );

      const filteredCampaignByObjective = group(
        allActiveCampaigns,
        ({ objective }) => objective
      );
      dispatch(
        addGroupedMetaCampaigns({
          grouped_campaigns: filteredCampaignByObjective
        })
      );

      //end

      // const preparedRows = parseMetaResponse(allCampaigns);
      const filteredCampaigns = filterCampaignData(allCampaigns);

      let metricsToBeRanked = parseCampaignMetricsData(allCampaignMetrics);
      const rankedCampaignList = rankCampaigns(metricsToBeRanked);

      dispatch(addFacebookCampaigns(filteredCampaigns));

      setStatusFilter("all");

      dispatch(changeLoadingStatus({ type: "campaigns", status: false }));
      dispatch(sortMetaCampaignsByRank(rankedCampaignList));
    } catch (err) {
      console.log(err);
      dispatch(changeLoadingStatus({ type: "campaigns", status: false }));
      setError(err?.stack);
    }
  };

  const selectedRows = useSelector(
    (store) => store.meta.campaigns[statusFilter]["data"]
  );
  const selectedRowsCount = useSelector(
    (store) => store.meta.campaigns[statusFilter]["total_count"]
  );
  const refetchStatus = useSelector((store) => store.meta.campaigns.refetch);

  //startup
  // useEffect(() => {
  //   const loadInitialData = async () => {
  //     const adAccountsResponses = await getAdAccounts();
  //     if (adAccountsResponses.length > 0) {
  //       setOwnedAdAccounts(adAccountsResponses);
  //       const firstAdAccount = adAccountsResponses[0];
  //       setSelectedAdAccount(firstAdAccount?.id);
  //     }
  //     // dispatch(sortMetaCampaignsByRank())
  //   };
  //   loadInitialData();
  // }, []);

  // useEffect(() => {
  // getAdAccountMetrics(selectedAdAccount);
  // getAdAccountCampaigns(selectedAdAccount);
  // dispatch(sortMetaCampaignsByRank());
  // }, [selectedAdAccount]);

  useEffect(() => {
    getAdAccountCampaigns(adAccountId);
  }, []);

  useEffect(() => {
    if (refetchStatus === true) {
      getAdAccountCampaigns(adAccountId);
      dispatch(setRefetchStatus({ type: "campaigns", status: false }));
    }
  }, [refetchStatus]);

  return <React.Fragment></React.Fragment>;
}
