import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../Title";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
// select
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import axios from "axios";
import env from "react-dotenv";
import { useDispatch, useSelector } from "react-redux";
import {
  addFacebookCampaigns,
  changeAdSetStatusFilter,
  searchMetaCampaigns,
  sortBySpendAmount,
  sortDataByDate,
  changeMetaStatusFilter,
  setRefetchStatus
} from "../../../store/reducers/facebook/CampaignListReducer";
import small_loading from "../../../assets/loading_small.gif";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import AdsetsDataRow from "./AdSetsDataRow";
import SyncIcon from "@mui/icons-material/Sync";

function preventDefault(event) {
  event.preventDefault();
}

export default function Campaigns(props) {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  //   const metalastUpdatedTime = useSelector((store) => store.meta.last_updated)
  const [loading, setLoading] = useState();

  const statusFilter = useSelector((store) => store.meta.adsets.selectedStatus);
  let rows =
    useSelector(
      (store) => store.meta.adsets?.filtered[campaignId]?.[statusFilter]?.data
    ) || [];
  const adSetsCount = useSelector(
    (store) =>
      store.meta.adsets?.filtered[campaignId]?.[statusFilter]?.total_count
  );
  let loadingStatus = useSelector((store) => store.meta.adsets.loading);
  let metaAdsetlastUpdatedTime = useSelector(
    (store) => store.meta.adsets.last_updated
  );

  const [selectedDateRange, setSelectedDateRange] = useState("");
  const dataLoadingStatus = useSelector((store) => store.meta.loading);

  //   const handleDateChange = (event, picker) => {
  //     setDateFrom(moment(picker.startDate).format("DD-MM-YYYY"))
  //     setDateTo(moment(picker.endDate).format("DD-MM-YYYY"))
  //     setSelectedDateRange(moment(picker.startDate).format("DD-MM-YYYY")+" - "+moment(picker.endDate).format("DD-MM-YYYY"))
  //   }

  const searchAdsetsByDateRange = (event) => {
    const dateRange = event.target.value;
    const [fromDate, toDate] = dateRange?.split(" - ");
    dispatch(
      sortDataByDate({
        type: "adsets",
        subjectId: campaignId,
        fromDate,
        toDate
      })
    );
    dispatch(changeMetaStatusFilter({ type: "adsets", filter: "search" }));
  };

  const searchAdsets = (query) => {
    dispatch(searchAdsets(query));
    dispatch(changeAdSetStatusFilter("search"));
  };

  const sortByAmount = () => {
    dispatch(sortBySpendAmount());
    dispatch(changeAdSetStatusFilter("search"));
  };

  const refetchAdsetData = () => {
    dispatch(setRefetchStatus({ type: "adsets", status: true }));
  };

  return (
    <React.Fragment>
      <Title>Ad sets {adSetsCount && "(" + adSetsCount + ")"}</Title>
      {!loadingStatus && (
        <span className="text-muted">
          Last updated at{" "}
          {moment(metaAdsetlastUpdatedTime).format("hh:mm:ss A")}
          &nbsp;
          <Button title="Refresh" onClick={refetchAdsetData}>
            <SyncIcon />
          </Button>
        </span>
      )}
      {!loadingStatus && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Filters</b>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statusFilter}
                    label="Status"
                    onChange={(e) => {
                      dispatch(changeAdSetStatusFilter(e.target.value));
                    }}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"paused"}>Paused</MenuItem>
                    <MenuItem value={"active"}>Active</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <DateRangePicker
                    initialSettings={{
                      startDate: "1/1/2023",
                      endDate: "1/12/2023",
                      locale: { format: "DD-MM-YYYY" }
                    }}
                    onEvent={searchAdsetsByDateRange}
                  >
                    <input
                      className="form-control"
                      type="text"
                      placeholder={selectedDateRange}
                    />
                  </DateRangePicker>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl>
                  <Input
                    type="text"
                    placeholder="Search here"
                    onChange={(e) => searchAdsets(e.target.value)}
                  ></Input>
                </FormControl>
              </TableCell>
              {/* <TableCell ><b>Amount spend</b></TableCell>
            <TableCell ><b>Budget Remaining</b></TableCell>  */}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              {/* <TableCell>
              <b>Impressions</b>
            </TableCell>
            <TableCell>
              <b>Amount spend<UnfoldMoreIcon/></b>
            </TableCell>
            <TableCell>
              <b>Daily budget</b>
            </TableCell>
            <TableCell>
              <b>Lifetime budget</b>
            </TableCell>
            <TableCell>
              <b>Reach</b>
            </TableCell> */}
              <TableCell>
                <b>Start time</b>
              </TableCell>
              <TableCell>
                <b>End time</b>
              </TableCell>
            </TableRow>
            {loadingStatus && (
              <TableRow>
                <TableCell>
                  <img src={small_loading} />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => (
                <AdsetsDataRow row={row} key={row.id}></AdsetsDataRow>
              ))}
          </TableBody>
        </Table>
      )}
    </React.Fragment>
  );
}
