import Linkedinlogo from "./Images/linkedin.png";
import MetaLogo from "./Images/meta.png";
import SnapchatLogo from "./Images/snapchat.png";
import GoogleAdsLogo from "./Images/google_ads.png";
import BingLogo from "./Images/bing.png";
import Loading from "./Images/loading.gif";
import FailedIcon from "./Images/failed_icon.png";
import EmptyResponseImage from "./Images/empty_data.png";

const Image = {
  linkedin: Linkedinlogo,
  meta: MetaLogo,
  snapchat: SnapchatLogo,
  google_ads: GoogleAdsLogo,
  bing: BingLogo,
  loading: Loading,
  failed: FailedIcon,
  empty_data: EmptyResponseImage
};

const getPlatformImage = (adPlatform, status = "") => {
  if (status == "loading") {
    return Image.loading;
  } else if (status == "failed") {
    return Image.failed;
  } else if (status == "empty_data") {
    return Image.empty_data;
  } else return Image[adPlatform];
};

export { Image, getPlatformImage };
