import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./listItems";
import AdPlatformSummary from "./Summary";
import GeneralComponent from "./general/GeneralComponent";
import AdPlatformCard from "../../components/DashboardPack/AdPlatformCard";
import { useParams } from "react-router-dom";
import axios from "../../utils/axios";
import SummaryCard from "../../components/DashboardPack/SummaryCard";
import moment from "moment";
import FilterMetricsModal from "../../components/common/general/FilterMetricsModal";
import { useSelector, useDispatch } from "react-redux";
import {
  changeSearchModalVisibility,
  changeReSearchFilter,
  changeRefetchingStatus
} from "../../store/reducers/common/CommonReducer";

//start

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
//end

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://webandcrafts.com">
        Webandcrafts.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}));

const mdTheme = createTheme();
function DashboardContent() {
  const { clientId } = useParams();
  const dispatch = useDispatch();

  const reSearchMetrics = useSelector(
    (store) => store.common.account_metric_filters.re_search
  );
  const selectedDateRange = useSelector(
    (store) => store.common.account_metric_filters.date_range
  );
  const selectedPlatform = useSelector(
    (store) => store.common.account_metric_filters.platform
  );
  const isFilterModalVisible = useSelector(
    (store) => store.common.account_metric_filters.is_filter_modal_active
  );
  const setIsFilterModalVisible = (e) => {
    dispatch(changeSearchModalVisibility(true));
  };
  const [clientDetails, setClientDetails] = React.useState();
  const [clientAdPlatforms, setClientAdPlatforms] = React.useState([]);
  const getClientInsights = async () => {
    try {
      let metricsFilterStartDate = selectedDateRange?.startDate;
      let metricsFilterEndDate = selectedDateRange?.endDate;
      if (!metricsFilterStartDate) {
        metricsFilterStartDate = moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD");
      }
      if (!metricsFilterEndDate) {
        metricsFilterEndDate = moment().format("YYYY-MM-DD");
      }

      const insightsUrl = `clients/${clientId}/insights?platform=${selectedPlatform}&start_date=${metricsFilterStartDate}&end_date=${metricsFilterEndDate}`;
      dispatch(changeReSearchFilter({ status: false }));
      const insightsDataResponse = await axios.get(insightsUrl);
    } catch (error) {
      //
    }
  };

  const getClientDetails = async () => {
    try {
      const clientDetailsResponse = await axios.get(`clients/${clientId}`);
      const clientDetails = clientDetailsResponse?.data?.data;
      setClientDetails(clientDetails);

      let clientAdPlatforms = clientDetails.ad_platforms;
      clientAdPlatforms = clientAdPlatforms.filter((adPlatform) => {
        return adPlatform.is_active == true;
      });
      setClientAdPlatforms(clientAdPlatforms);
    } catch (error) {}
  };

  React.useEffect(() => {
    getClientDetails();
    getClientInsights();
    dispatch(changeRefetchingStatus({ platform: "all", status: false }));
    return () => {};
  }, []);

  React.useEffect(() => {
    console.log("RE-Search " + reSearchMetrics);
    if (reSearchMetrics === true) {
      getClientInsights();
    }
  }, [reSearchMetrics]);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px" // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard {" - " + clientDetails?.name}
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={false}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1]
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {/* <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List> */}
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        setIsFilterModalVisible(true);
                      }}
                      style={{ float: "right" }}
                    >
                      Filter
                    </Button>
                  </Box>

                  <SummaryCard />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column"
                    // height: 240,
                  }}
                >
                  <Box sx={{ minWidth: 120 }}>
                    {isFilterModalVisible && (
                      <FilterMetricsModal isOpen={isFilterModalVisible} />
                    )}
                  </Box>

                  {/* <AdPlatformSummary ad_platform={"tiktok"} /> */}
                  {clientAdPlatforms.map((clientPlatform) => (
                    <AdPlatformCard
                      ad_platform={clientPlatform?.name}
                      ad_account_id={clientPlatform.account_id}
                      key={clientPlatform?._id}
                    />
                  ))}
                </Paper>
              </Grid>

              {/* Recent Deposits */}
              {/* Recent Orders */}
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
