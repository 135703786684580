import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import { useEffect, useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  addCampaignAdsets,
  addAdsets,
  changeLoadingStatus,
  setRefetchStatus
} from "../../../store/reducers/facebook/CampaignListReducer";
import small_loading from "../../../assets/loading_small.gif";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { getCurrencySymbol } from "../../../utils/currencySymbols";
import appAxios from "../../../utils/axios";

function preventDefault(event) {
  event.preventDefault();
}

export default function AdsetSummary() {
  const [error, setError] = useState("");
  const loading = useSelector((store) => store.meta.ads.loading);
  const [adSetSummary, setAdSetSummary] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState("");

  //store
  const dispatch = useDispatch();

  //router params
  const { clientId, adSetId } = useParams();

  //methods
  const prepareAdSetResponse = (response) => {
    const { basic, insights, ads } = response;

    const name = basic?.name;
    const id = basic?.id;

    const spend_amount = insights?.spend;
    const ads_count = ads.length;

    const reaches = insights?.reach;
    const currency = insights?.account_currency;
    setCurrencySymbol(getCurrencySymbol(currency));
    return { id, name, ads, spend_amount, ads_count, reaches, currency };
  };

  const getAdSetSummary = async (adSetId) => {
    dispatch(changeLoadingStatus({ type: "ads", status: true }));
    setError("");
    const url = `clients/${clientId}/meta/${adSetId}/adset-metrics`;
    const fieldsExpression = `?fields=id,name,status,insights{reach,spend,account_currency},daily_budget,lifetime_budget,start_time,end_time,ads{id,status,insights{reach,impressions,spend,ctr,account_currency,cpc},name}`;

    try {
      const response = await appAxios.get(url);
      dispatch(changeLoadingStatus({ type: "ads", status: false }));
      return response?.data?.data;
    } catch (error) {
      dispatch(changeLoadingStatus({ type: "ads", status: false }));
      setError(error?.message);
    }
  };

  const loadInitialData = async () => {
    const adSetSummary = await getAdSetSummary(adSetId);
    const preparedResponse = prepareAdSetResponse(adSetSummary);
    const ads = preparedResponse?.ads;
    setAdSetSummary(preparedResponse);
    dispatch(addAdsets({ adSetId, data: ads }));
  };

  //startup
  useEffect(() => {
    loadInitialData();
  }, []);

  const refetchStatus = useSelector((store) => store.meta.ads.refetch);
  useEffect(() => {
    if (refetchStatus === true) {
      loadInitialData();
      dispatch(setRefetchStatus({ type: "ads", status: false }));
    }
  }, [refetchStatus]);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8} md={8}>
            <Title>{adSetSummary && adSetSummary?.name}</Title>
            <Typography component="p" variant="caption">
              Ad Set ID : {adSetId}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              Total ads : {adSetSummary && adSetSummary?.ads_count}
            </Typography>
          </Grid>
          <Typography
            color="text.secondary"
            sx={{ flex: 1 }}
            variant="h6"
            className={`text-danger`}
          >
            {error && error}
          </Typography>
          {loading && (
            <div>
              <img
                height="100px"
                src={small_loading}
                style={{ position: "relative", zIndex: "100" }}
              />
            </div>
          )}
          {!loading && (
            <Grid item xs={4} md={4}>
              <Typography component="p" variant="h6">
                Total amount spent :{" "}
              </Typography>
              <Typography component="p" variant="h2">
                {adSetSummary && adSetSummary?.spend_amount}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}
