import React from "react";

const Carddata = (props) => {
  return (
    <>
      <div className="card">
        <h1 className="card__title">{props.cardTitle}</h1>
        <div className="card__content">
          <h2 className="card__subtitle">{props.cardSubtitle}</h2>
          <div
            className={`card__description text-${
              props?.status ? props?.status : "danger"
            }`}
          >
            {props.cardDescription}
          </div>
        </div>
      </div>
    </>
  );
};

export default Carddata;
