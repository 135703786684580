import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";

import { Link } from "react-router-dom";
import moment from "moment";
import { getCurrencySymbol } from "../../../utils/currencySymbols";
import { useParams } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CampaignDataRow = ({ row }) => {
  console.log(JSON.stringify(row));
  const { clientId } = useParams();
  const startTime = moment(row.start_time).format("MMMM DD,YYYY HH:mm");
  const endTime = moment(row.end_time).format("MMMM DD,YYYY HH:mm");
  const insights = row?.insights;

  const currencySymbol = getCurrencySymbol(insights?.account_currency);
  const budgetRemaining =
    Number(row?.budget_remaining) && currencySymbol
      ? +row?.budget_remaining
      : row?.budget_remaining;
  const [open, setOpen] = React.useState(true);

  let conversionsString = "";
  if (insights && insights?.conversions && insights?.conversions.length > 0) {
    let conversions = insights.conversions;
    conversions.forEach((conversion) => {
      conversionsString += `${conversion?.action_type} : ${conversion?.value}<br/>`;
    });
  }
  return (
    <>
      <TableRow key={row.id}>
        <TableCell>
          <Link to={`/${clientId}/platforms/meta/campaign-metrics/${row.id}`}>
            {row?.name || "-"}
          </Link>
        </TableCell>
        <TableCell>{row?.status || "-"}</TableCell>
        <TableCell>{row?.objective || "-"}</TableCell>
      </TableRow>
      {insights && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="metrics">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Start date</b>
                      </TableCell>
                      <TableCell>
                        <b>End date</b>
                      </TableCell>
                      <TableCell>
                        <b>Impressions</b>
                      </TableCell>
                      <TableCell>
                        <b>Reach</b>
                      </TableCell>
                      <TableCell>
                        <b>Cost</b>
                      </TableCell>
                      <TableCell>
                        <b>CPC</b>
                      </TableCell>
                      <TableCell>
                        <b>Clicks</b>
                      </TableCell>
                      <TableCell>
                        <b>CTR</b>
                      </TableCell>
                      <TableCell>
                        <b>Conversions</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={insights.id}>
                      <TableCell>{insights?.date_start}</TableCell>
                      <TableCell>{insights?.date_stop}</TableCell>
                      <TableCell>{insights?.impressions}</TableCell>
                      <TableCell>{insights?.reach}</TableCell>
                      <TableCell>{insights?.spend}</TableCell>
                      <TableCell>{insights?.cpc}</TableCell>
                      <TableCell>{insights?.clicks}</TableCell>
                      <TableCell>{insights?.ctr}</TableCell>
                      <TableCell>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: conversionsString
                          }}
                        ></div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default CampaignDataRow;
