import "./App.css";
import Dashboard from "./pages/facebook/Dashboard";
import AdsetDashboard from "./pages/facebook/AdsetsDashboard";
import AdsDashboard from "./pages/facebook/AdsDashboard";
import CommonDashboard from "./pages/common/CommonDashboard";
import AdDetailDashboard from "./pages/facebook/AdDetailDashboard";
import LinkedInCampaignsDashboard from "./pages/linkedin/LinkedInCampaignsDashboard";
import CreateClient from "./pages/create_client/Create_client";
import SnapchatCampaignDashboardPage from "./pages/snapchat/SnapchatCampaignDashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UpdateClient from "./pages/update_client/Update_client";
import ClientListing from "./pages/client_listing/Client_listing";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<CommonDashboard />} /> */}
        <Route path="/" element={<ClientListing />} />
        <Route path="/clients/:clientId" element={<CommonDashboard />} />
        <Route
          path="/platforms/meta/:clientId/ad-accounts/:adAccountId"
          element={<Dashboard />}
        />
        <Route
          path="/platforms/linkedin/:clientId/ad-accounts/:adAccountId"
          element={<LinkedInCampaignsDashboard />}
        />
        <Route
          path="/platforms/snapchat/:clientId/ad-accounts/:adAccountId"
          element={<SnapchatCampaignDashboardPage />}
        />

        <Route
          path="/:clientId/platforms/meta/campaign-metrics/:campaignId"
          element={<AdsetDashboard />}
        />
        <Route
          path="/:clientId/platforms/meta/adset-metrics/:adSetId"
          element={<AdsDashboard />}
        />
        <Route
          path="/:clientId/platforms/meta/ad-metrics/:adId"
          element={<AdDetailDashboard />}
        />
        {/* <Route
          path="/platforms/linkedin/:clientId"
          element={<LinkedInAdsDashboard />}
        /> */}
        <Route path="/clients/create" element={<CreateClient />} />
        <Route path="/clients/update/:clientId" element={<UpdateClient />} />
        <Route path="/clients" element={<ClientListing />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
