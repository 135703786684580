import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import moment from "moment";
import { getCurrencySymbol } from "../../../utils/currencySymbols";
import { useParams } from "react-router-dom";

const AdsetsDataRow = ({ row }) => {
  const { clientId } = useParams();
  const insights = row?.insights?.data[0];
  const startTime = moment(insights?.start_time).format("DD-MM-YYYY HH:mm:ss");
  const endTime = moment(insights?.end_time).format("DD-MM-YYYY HH:mm:ss");
  const reaches = insights?.reach;
  const impressions = insights?.impressions;
  const spend_amount = insights?.spend;
  const currency = insights?.account_currency;
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <TableRow key={row.id}>
      <TableCell>
        <Link to={`/${clientId}/platforms/meta/adset-metrics/${row.id}`}>
          {row?.name || "-"}
        </Link>
      </TableCell>
      <TableCell>{row?.status || "-"}</TableCell>
      {/* <TableCell>{impressions || "-"}</TableCell>
        <TableCell>{spend_amount?currencySymbol+spend_amount:"-"}</TableCell>
        <TableCell>{row?.daily_budget || "-"}</TableCell>
        <TableCell>{row?.lifetime_budget || "-"}</TableCell>
        <TableCell>{reaches || "-"}</TableCell> */}
      <TableCell>{startTime || "-"}</TableCell>
      <TableCell>{endTime || "-"}</TableCell>
    </TableRow>
  );
};

export default AdsetsDataRow;
