import "./App.css";
import DataRowImg from "./DashboardComponents/Components/DataRowImg";
import DataRowItem from "./DashboardComponents/Components/DataRowItem";
import LinkRowItem from "./DashboardComponents/Components/LinkRowItem";
import { getPlatformImage } from "./DashboardComponents/Assets/Assets";
import { useParams, Link } from "react-router-dom";
import pusher from "../../utils/pusher";
import env from "react-dotenv";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPlatformAdAccountSummaryMetrics } from "../../store/reducers/common/CommonReducer";
import { Image } from "./DashboardComponents/Assets/Assets";
import { naiveRound, computeCpaForMeta } from "../../utils/helpers";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

function AdPlatformCard({ ad_platform, ad_account_id }) {
  // Listen for ad data pushes

  const dispatch = useDispatch();
  const appName = env.REACT_APP_NAME;

  const isRefetchingData = useSelector(
    (store) => store.common.loading_statuses["all"]
  );

  useEffect(() => {
    if (isRefetchingData == true) {
      setPlatformImage(getPlatformImage(ad_platform, "loading"));
    } else {
      setPlatformImage(getPlatformImage(ad_platform, ""));
    }
  }, [isRefetchingData]);

  const { clientId } = useParams();
  const channelName = `${appName}_${clientId}_updates`;
  const [cpaInState, setCpaInState] = useState(0);
  const [adPlatformData, setAdPlaformData] = useState({
    cpa: 0,
    cpc: 0,
    account_name: "",
    impressions: 0,
    spend: 0,
    clicks: 0
  });

  const reSearchStatus = useSelector(
    (store) => store.common.account_metric_filters.re_search
  );

  useEffect(() => {
    setAdPlaformData({
      cpa: 0,
      cpc: 0,
      account_name: "",
      impressions: 0,
      spend: 0,
      clicks: 0
    });
    setCpaInState(0);
  }, [reSearchStatus]);

  const [platformImage, setPlatformImage] = useState(
    getPlatformImage(ad_platform, "")
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const viewClientDetailsLink =
    ad_platform != "snapchat"
      ? `/platforms/${ad_platform}/${clientId}/ad-accounts/${ad_account_id}`
      : "#";
  // const adAccountDetails = useSelector(
  //   (store) => store.common.platforms[ad_platform]["ad_accounts"][ad_account_id]
  // );
  const listenForDataUpdates = () => {
    const pusherChannel = pusher.subscribe(channelName);

    const adAccountSummaryStorePath = ``;

    //listen for data payload
    pusherChannel.bind(`${ad_platform}_data_payload`, function (data) {
      console.log(data);
      setAdPlaformData(data);
      setCpaInState(0);
      dispatch(
        setPlatformAdAccountSummaryMetrics({
          platform: ad_platform,
          ad_account_id: data.id,
          ad_account_details: data
        })
      );
      setPlatformImage(getPlatformImage(ad_platform, ""));
      setIsLoading(false);
    });

    //listen for data status change
    pusherChannel.bind(`${ad_platform}_data_status`, function (data) {
      setIsLoading(false);
      if (data.status == "failed") {
        console.log("failed!");
        setError(data.message);
        console.log(error);
        setPlatformImage(getPlatformImage(ad_platform, "failed"));
      }
      if (data.status == "empty_data") {
        console.log("empty data!");
        setError(data.message);
        console.log(error);
        setPlatformImage(getPlatformImage(ad_platform, "empty_data"));
      }
    });
  };

  const conversionList = adPlatformData?.conversions;
  const handleConversionChange = (e) => {
    const currentActionType = e.target.value;
    const computedCpa = computeCpaForMeta(
      conversionList,
      currentActionType,
      adPlatformData?.spend
    );
    setCpaInState(computedCpa);
  };

  useEffect(() => {
    listenForDataUpdates();
    return () => {
      pusher.unsubscribe(channelName);
    };
  }, []);

  return (
    <div className="App">
      {/* start */}
      {ad_platform == "meta" && conversionList && (
        <FormControl>
          <br />
          <FormLabel id="demo-row-radio-buttons-group-label">
            Conversions filter
          </FormLabel>
          <RadioGroup
            onChange={handleConversionChange}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {conversionList.map((conversion) => {
              return (
                <FormControlLabel
                  value={conversion.action_type}
                  control={<Radio />}
                  label={conversion.action_type}
                  key={conversion.action_type}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      )}
      {adPlatformData?.date_start && adPlatformData?.date_stop && (
        <span style={{}}>
          <b>Date Range: </b>
          <i>
            {adPlatformData?.date_start + " - " + adPlatformData?.date_stop}
          </i>
        </span>
      )}
      {/* end */}
      <div className="box-container">
        <div>
          <div className="datarow-wrap">
            <DataRowImg
              logo={platformImage}
              caption={adPlatformData?.account_name}
            />
            <DataRowItem
              status={"success"}
              title="Impressions"
              content={adPlatformData?.impressions || 0}
              report=""
            />
            <DataRowItem
              status={"success"}
              title="Clicks"
              content={adPlatformData?.clicks || 0}
              report=""
            />
            <DataRowItem
              status={"danger"}
              title="CPC"
              content={adPlatformData?.cpc || 0}
              report=""
            />
            {/* <DataRowItem
              status={"success"}
              title="Conversions"
              content={adPlatformData.conversions}
              report="2527.38%"
            /> */}
            <DataRowItem
              status={"success"}
              title="Cost"
              content={adPlatformData?.spend || 0}
              report=""
            />
            <DataRowItem
              status={"success"}
              title="CPA"
              content={cpaInState || 0}
              report=""
            />

            <LinkRowItem
              text="Last month insights"
              href={viewClientDetailsLink}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdPlatformCard;
