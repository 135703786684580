import env from "react-dotenv";
import axios from "axios";
const baseURL = env.REACT_APP_API_HOOK;

const instance = axios.create({
  baseURL,
  timeout: 10000
});

export default instance;
