import { createSlice } from '@reduxjs/toolkit'
import moment from "moment"
import _ from "lodash"

const initialState = {}

const metaCampaignReducerSlice = createSlice({
    name: 'meta_campaigns',
    initialState,
    reducers: {
        addMetaCampaigns(state,action) {
            state["campaigns"]["all"] = action?.payload
        }
    },
  })
  
export const {addMetaCampaigns}=metaCampaignReducerSlice.actions
export default metaCampaignReducerSlice.reducer
