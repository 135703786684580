import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { getCurrencySymbol } from "../../../utils/currencySymbols";

const AdsDataRow = ({ row }) => {
  const spend_amount = row?.insights?.data[0].spend;
  const impressions = row?.insights?.data[0].impressions;
  const reaches = row?.insights?.data[0].reach;
  const startTime =
    moment(row?.insights?.data[0].date_start).format("DD-MM-YYYY HH:mm:ss") ||
    "-";
  const endTime =
    moment(row?.insights?.data[0].date_stop).format("DD-MM-YYYY HH:mm:ss") ||
    "-";
  const currency = row?.insights?.data[0].account_currency;
  const currencySymbol = getCurrencySymbol(getCurrencySymbol);
  const costPerClick = row?.insights?.data[0].cpc;
  const ctr = row?.insights?.data[0].ctr;

  const { clientId } = useParams();

  return (
    <TableRow key={row.id}>
      <Link to={`/${clientId}/platforms/meta/ad-metrics/${row.id}`}>
        {row?.name || "-"}
      </Link>
      <TableCell>{row?.status || "-"}</TableCell>
      {/* <TableCell>{impressions || "-"}</TableCell>
        <TableCell>{spend_amount || "-"}</TableCell>
        <TableCell>{costPerClick || "-"}</TableCell> 
        <TableCell>{ ctr|| "-"}</TableCell> 
        <TableCell>{reaches||"-"}</TableCell> */}
      <TableCell>{startTime || "-"}</TableCell>
      <TableCell>{endTime || "-"}</TableCell>
    </TableRow>
  );
};

export default AdsDataRow;
