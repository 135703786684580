import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  addCampaignAdsets,
  changeLoadingStatus,
  setRefetchStatus
} from "../../../store/reducers/facebook/CampaignListReducer";
import small_loading from "../../../assets/loading_small.gif";
import { useParams } from "react-router-dom";
import { getCurrencySymbol } from "../../../utils/currencySymbols";
import appAxios from "../../../utils/axios";

function preventDefault(event) {
  event.preventDefault();
}

export default function AdsetSummary() {
  const [selectedCampaign, setselectedCampaign] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [campaignSummary, setCampaignSummary] = useState(null);
  const [campaignDetailsFetched, setCampaignDetailsFetched] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState("");

  //store
  const dispatch = useDispatch();

  //router params
  const { clientId, campaignId } = useParams();

  //methods
  const prepareCampaignSummary = (rawCampaignSummary) => {
    const adsets = rawCampaignSummary?.adsets || [];
    const insights = rawCampaignSummary?.insights;
    const impressions = insights?.impressions;
    const currency = insights?.account_currency;
    const clicks = insights?.clicks;

    const spend_amount = insights?.spend;
    const adsets_count = adsets.length;
    setCurrencySymbol(getCurrencySymbol(currency));

    return {
      id: rawCampaignSummary?.basic?.id,
      name: rawCampaignSummary?.basic?.name,
      status: rawCampaignSummary?.basic?.status,
      impressions,
      currency,
      clicks,
      spend_amount,
      adsets_count
    };
  };

  const getAdAccountCampaigns = async () => {
    setLoading(true);
    dispatch(changeLoadingStatus({ type: "adsets", status: true }));
    // const metaAccessToken = env.REACT_APP_META_ACCESS_KEY;
    // const fieldsExpression = `?fields=id,name,insights{account_currency,spend,reach},adsets{id,name,insights{account_currency,reach,impressions,ctr,spend},start_time,end_time,daily_budget,lifetime_budget,status,ads{name,id,insights}}&limit=1000`;
    // let url = `https://graph.facebook.com/v15.0/${campaignId}${fieldsExpression}`;

    try {
      const adCampaignSummaryUrl = `clients/${clientId}/meta/${campaignId}/campaign-metrics`;
      const campaignSummaryResponse = await appAxios.get(adCampaignSummaryUrl);
      // const response = await axios.get(url, {
      //   headers: { Authorization: `Bearer ${metaAccessToken}` }
      // });
      setLoading(false);
      dispatch(changeLoadingStatus({ type: "adsets", status: false }));
      return campaignSummaryResponse?.data?.data;
    } catch (error) {
      setError(error?.message);
      dispatch(changeLoadingStatus({ type: "adsets", status: false }));
      setLoading(false);
    }
  };

  const loadInitialData = async () => {
    if (!campaignDetailsFetched) {
      const campaignSummaryResponse = await getAdAccountCampaigns();
      const preparedCampaignSummary = prepareCampaignSummary(
        campaignSummaryResponse
      );
      dispatch(
        addCampaignAdsets({
          data: campaignSummaryResponse?.adsets,
          campaignId
        })
      );
      setCampaignSummary(preparedCampaignSummary);
      setCampaignDetailsFetched(true);
    }
  };

  //startup
  useEffect(() => {
    loadInitialData();
  }, []);

  let adsetRefetchStatus = useSelector((store) => store.meta.adsets.refetch);
  useEffect(() => {
    if (adsetRefetchStatus === true) {
      loadInitialData();
      dispatch(setRefetchStatus({ type: "adsets", status: false }));
    }
  }, [adsetRefetchStatus]);

  const amountSpent = campaignSummary?.spend_amount || "";

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Title>{campaignSummary && campaignSummary?.name}</Title>
            <Typography component="p" variant="caption">
              Campaign ID : {campaignId}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              Total adsets : {campaignSummary && campaignSummary?.adsets_count}
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ flex: 1 }}
              variant="h6"
              className={`text-danger`}
            >
              {error && error}
            </Typography>
            {loading && (
              <div>
                <img
                  height="100px"
                  src={small_loading}
                  style={{ position: "relative", zIndex: "100" }}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography component="p" variant="h6">
              Total amount spent :{" "}
            </Typography>
            <Typography component="p" variant="h2">
              {amountSpent && amountSpent}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
