import * as React from "react";
import { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../Title";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
// select
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import axios from "axios";
import env from "react-dotenv";
import { useDispatch, useSelector } from "react-redux";
import {
  addFacebookCampaigns,
  searchMetaCampaigns,
  changeLoadingStatus,
  sortBySpendAmount,
  sortDataByDate,
  changeMetaStatusFilter,
  setRefetchStatus
} from "../../../store/reducers/facebook/CampaignListReducer";
import small_loading from "../../../assets/loading_small.gif";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SyncIcon from "@mui/icons-material/Sync";

import AdsDataRow from "./AdsDetailDataRow";

function preventDefault(event) {
  event.preventDefault();
}

export default function AdsListing(props) {
  const { adId } = useParams();
  const dispatch = useDispatch();

  const adDataRow = useSelector(
    (store) => store.meta.ads?.filtered[adId]?.data
  );
  const adsCount = useSelector(
    (store) => store.meta.ads?.filtered[adId]?.total_count
  );

  const [selectedDateRange, setSelectedDateRange] = useState("");
  const dataLoadingStatus = useSelector((store) => store.meta.ads.loading);

  //   const handleDateChange = (event, picker) => {
  //     setDateFrom(moment(picker.startDate).format("DD-MM-YYYY"))
  //     setDateTo(moment(picker.endDate).format("DD-MM-YYYY"))
  //     setSelectedDateRange(moment(picker.startDate).format("DD-MM-YYYY")+" - "+moment(picker.endDate).format("DD-MM-YYYY"))
  //   }

  const searchCampaigns = (query) => {
    dispatch(searchMetaCampaigns(query));
    // dispatch(changeStatusFilter("search"))
  };

  const searchAdsByDateRange = (event) => {
    const dateRange = event.target.value;
    const [fromDate, toDate] = dateRange?.split(" - ");
    dispatch(sortDataByDate({ type: "ads", fromDate, toDate }));
    dispatch(changeMetaStatusFilter({ type: "ads", filter: "search" }));
  };

  const sortByAmount = () => {
    dispatch(sortBySpendAmount());
    // dispatch(changeStatusFilter("search"))
  };

  const metalastUpdatedTime = useSelector(
    (store) => store.meta.ads.last_updated
  );

  const refetchAdsData = () => {
    dispatch(setRefetchStatus({ type: "ads", status: true }));
  };

  return (
    <React.Fragment>
      <Title>Ad Details </Title>
      {!dataLoadingStatus && (
        <span className="text-muted">
          Last updated at {moment(metalastUpdatedTime).format("hh:mm:ss A")}
          &nbsp;
          <Button title="Refresh" onClick={refetchAdsData}>
            <SyncIcon />
          </Button>
        </span>
      )}
      {dataLoadingStatus && (
        <span>
          <img src={small_loading} height="" width="" />
        </span>
      )}
      {!dataLoadingStatus && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Impressions</b>
              </TableCell>
              <TableCell>
                <b>
                  Amount spend
                  <UnfoldMoreIcon />
                </b>
              </TableCell>
              <TableCell>
                <b>Cost per click</b>
              </TableCell>
              <TableCell>
                <b>CTR</b>
              </TableCell>
              <TableCell>
                <b>Reaches</b>
              </TableCell>
              <TableCell>
                <b>Start time</b>
              </TableCell>
              <TableCell>
                <b>End time</b>
              </TableCell>
            </TableRow>
          </TableHead>
          {/* {dataLoadingStatus && <TableRow><img src={ small_loading}/></TableRow>} */}
          <TableBody>
            {adDataRow && (
              <AdsDataRow row={adDataRow} key={adDataRow}></AdsDataRow>
            )}
          </TableBody>
        </Table>
      )}
    </React.Fragment>
  );
}
