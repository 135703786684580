import _ from "lodash";

const filterCampaignData = (campaigns) => {
  const activeCampaigns = campaigns.filter((campaign) => {
    return campaign.status == "ACTIVE";
  });
  const pausedCampaigns = campaigns.filter((campaign) => {
    return campaign.status == "PAUSED";
  });

  return {
    all_campaigns: campaigns,
    paused_campaigns: pausedCampaigns,
    active_campaigns: activeCampaigns
  };
};

const rankCampaignsByParam = (campaigns, insightParam) => {
  const sortedList = _.sortBy(campaigns, insightParam);
  return sortedList;
};

const rankCampaigns = (campaigns, order = "desc", resultArrayCount = 20) => {
  const preparedCampaigns = campaigns.filter((campaign) => {
    const campaignInsights = campaign?.metrics;

    const id = campaign?.id;
    const name = campaign?.campaign_name;

    const impressions = campaignInsights?.impressions;
    const clicks = campaignInsights?.clicks;
    const spend = campaignInsights?.costInLocalCurrency;
    const conversion_value = campaignInsights?.conversionValueInLocalCurrency;
    return { id, name, spend, impressions, conversion_value, clicks };
  });

  let sortCampaignList = _.sortBy(preparedCampaigns, "spend");
  if ((order = "asc")) {
    sortCampaignList = sortCampaignList.reverse().slice(0, resultArrayCount);
  } else {
    sortCampaignList = sortCampaignList.slice(0, resultArrayCount);
  }
  return sortCampaignList;
};
export { filterCampaignData, rankCampaigns };
