import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import env from "react-dotenv";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import pusher from "../../../utils/pusher";
import axios from "../../../utils/axios";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { createPortal } from "react-dom";
import loader1 from "../../../assets/loader-1.gif";
import loader2 from "../../../assets/loader-2.gif";
import loader3 from "../../../assets/loader-3.gif";
import WaiterComponent from "./WaiterComponent";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

export default function ClientDetails() {
  const { clientId } = useParams();
  const [clientDetails, setClientDetails] = useState();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const getClientDetails = async () => {
    try {
      const response = await axios.get(`clients/${clientId}`);
      const fetchedClientDetails = response?.data?.data;
      setClientDetails(fetchedClientDetails);
    } catch (error) {
      console.log("ERROR ", error);
    }
  };

  useEffect(() => {
    getClientDetails();
    const appName = env.REACT_APP_NAME;
    const channelName = `${appName}_${clientId}_updates`;
    const pusherChannel = pusher.subscribe(channelName);
    // pusherChannel.bind("event", function (data) {
    //   console.log("data", data);
    // });

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Item>
            <Typography variant="div" component="div">
              <img src="https://picsum.photos/200" />
              <br />
              {clientDetails && <b>clientDetails?.name</b>}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={8}>
          {clientDetails && <Item> </Item>}
        </Grid>
      </Grid>
    </Box>
  );
}
