import React from "react";

function LinkRowItem({ text, href }) {
  return (
    <>
      <div className="datarow-item">
        <a className="datarow-item__content" href={href}>
          {text}
        </a>
      </div>
    </>
  );
}

export default LinkRowItem;
