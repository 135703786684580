import * as React from "react";
import { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../Title";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
// select
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CampaignDataRow from "./CampaignDataRow";
import { useParams } from "react-router-dom";
import axios from "axios";
import env from "react-dotenv";
import { useDispatch, useSelector } from "react-redux";
import {
  addFacebookCampaigns,
  changeCampaignStatusFilter,
  searchMetaCampaigns,
  changeLoadingStatus,
  sortBySpendAmount,
  sortDataByDate,
  changeMetaStatusFilter,
  setRefetchStatus
} from "../../../store/reducers/snapchat/SnapchatCampaignReducer";
import small_loading from "../../../assets/loading_small.gif";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SyncIcon from "@mui/icons-material/Sync";

function preventDefault(event) {
  event.preventDefault();
}

export default function Campaigns(props) {
  const dispatch = useDispatch();
  const { adAccountId } = useParams();

  const groupedCampaigns = useSelector(
    (store) => store.snapchat?.campaigns?.[adAccountId]
  );

  const metalastUpdatedTime = useSelector(
    (store) => store.snapchat.campaigns.last_updated
  );

  const statusFilter = useSelector(
    (store) => store.snapchat.campaigns.selectedStatus
  );
  let rows = useSelector(
    (store) => store.snapchat.campaigns.filtered?.[statusFilter]?.data
  );

  let campaignsCount = useSelector(
    (store) => store.snapchat.campaigns.filtered[statusFilter]?.total_count
  );
  let campaignsLoading = useSelector(
    (store) => store.snapchat.campaigns.loading
  );

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("");

  const handleDateChange = (event, picker) => {
    setDateFrom(moment(picker.startDate).format("DD-MM-YYYY"));
    setDateTo(moment(picker.endDate).format("DD-MM-YYYY"));
    setSelectedDateRange(
      moment(picker.startDate).format("DD-MM-YYYY") +
        " - " +
        moment(picker.endDate).format("DD-MM-YYYY")
    );
  };

  const searchCampaignsByDateRange = (event) => {
    const dateRange = event.target.value;
    console.log("dateRange", dateRange);
    const [fromDate, toDate] = dateRange?.split(" - ");
    dispatch(sortDataByDate({ type: "campaigns", fromDate, toDate }));
    dispatch(changeMetaStatusFilter({ type: "campaigns", filter: "search" }));
  };

  const sortByAmount = () => {
    dispatch(sortBySpendAmount());
    dispatch(changeCampaignStatusFilter("search"));
  };

  const refetchCampaignData = () => {
    dispatch(setRefetchStatus({ type: "campaigns", status: true }));
  };

  return (
    <React.Fragment>
      <Title>
        Recent Snapchat Campaigns {campaignsCount && "(" + campaignsCount + ")"}
      </Title>
      {!campaignsLoading && (
        <>
          <span className="text-muted">
            Last updated at {moment(metalastUpdatedTime).format("hh:mm:ss A")}
            &nbsp;
            <Button title="Refresh" onClick={refetchCampaignData}>
              <SyncIcon />
            </Button>
          </span>
          <FormControl fullWidth style={{ float: "left" }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={statusFilter}
              label="Status"
              onChange={(e) => {
                dispatch(changeCampaignStatusFilter(e.target.value));
              }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"paused"}>Paused</MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      {campaignsLoading && (
        <span>
          <div className="align-center">
            <img src={small_loading} className="loader-icon" />
          </div>
        </span>
      )}
      {!campaignsLoading && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Filters</b>
              </TableCell>
              <TableCell></TableCell>
              {/* <TableCell>
                <FormControl fullWidth>
                  <DateRangePicker
                    initialSettings={{
                      startDate: "1/1/2023",
                      endDate: "1/12/2023",
                      locale: { format: "DD-MM-YYYY" }
                    }}
                    onEvent={searchCampaignsByDateRange}
                  >
                    <input
                      type="text"
                      placeholder={selectedDateRange}
                      className="form-control"
                    />
                  </DateRangePicker>
                </FormControl>
              </TableCell> */}
              {/* <TableCell>
                <FormControl>
                  <Input
                    type="text"
                    placeholder="Search here"
                    onChange={(e) => searchCampaignsByDateRange(e.target.value)}
                  ></Input>
                </FormControl>
              </TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              {/* <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Objective</b>
              </TableCell> */}
              {/* <TableCell>
              <b>Impressions</b>
            </TableCell>
            <TableCell>
              <b>Currency</b>
            </TableCell>   
            <TableCell>
              <b>Reach</b>
            </TableCell>
            <TableCell>
              <b>Amount spend<UnfoldMoreIcon onClick={(e) => { console.log("jewhru"); sortByAmount()} } title="sort" /></b>
            </TableCell>
            <TableCell>
              <b>Budget Remaining</b>
            </TableCell> */}
              {/* <TableCell>
                <b>Start time</b>
              </TableCell>
              <TableCell>
                <b>End time</b>
              </TableCell> */}
            </TableRow>
            {campaignsLoading && (
              <TableRow>
                <img src={small_loading} />
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => (
                <CampaignDataRow row={row} key={row?.id}></CampaignDataRow>
              ))}
          </TableBody>
        </Table>
      )}
    </React.Fragment>
  );
}
