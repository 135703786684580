import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";

import { Link } from "react-router-dom";
import moment from "moment";
import { getCurrencySymbol } from "../../../utils/currencySymbols";
import { useParams } from "react-router-dom";

const CampaignDataRow = ({ row }) => {
  const { clientId } = useParams();
  const [open, setOpen] = React.useState(true);
  console.log(JSON.stringify(row));
  const insights = row?.metrics;
  // const insights = row
  // const currencySymbol = getCurrencySymbol(insights?.account_currency);
  // const budgetRemaining =
  //   Number(row?.budget_remaining) && currencySymbol
  //     ? +row?.budget_remaining
  //     : row?.budget_remaining;
  let startDate, endDate, dateRangeHtmlString;
  if (insights) {
    const { dateRange } = insights;
    startDate =
      dateRange?.start?.day +
      "-" +
      dateRange?.start?.month +
      "-" +
      dateRange?.start?.year;
    endDate =
      dateRange?.end?.day +
      "-" +
      dateRange?.end?.month +
      "-" +
      dateRange?.end?.year;
    dateRangeHtmlString = `<b>${startDate}</b> to <b>${endDate}</b>`;
  }
  return (
    <>
      <TableRow key={row.id}>
        <TableCell>
          <Link to="#">{row?.name || "-"}</Link>
        </TableCell>
        {/* <TableCell>{row?.status || "-"}</TableCell>
      <TableCell>{row?.objective || "-"}</TableCell>
      <TableCell>{startTime || "-"}</TableCell>
      <TableCell>{endTime || "-"}</TableCell> */}
      </TableRow>
      {!insights && (
        <TableRow>
          <TableCell style={{ color: "red" }}>
            <b>No insights found for this period</b>
          </TableCell>
        </TableRow>
      )}
      {insights && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="metrics">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Cost In Local Currency</b>
                      </TableCell>
                      <TableCell>
                        <b>Impressions</b>
                      </TableCell>
                      <TableCell>
                        <b>Clicks</b>
                      </TableCell>
                      <TableCell>
                        <b>conversionValueInLocalCurrency</b>
                      </TableCell>
                      <TableCell>
                        <b>DateRange</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={insights.id}>
                      <TableCell>{insights?.costInLocalCurrency}</TableCell>
                      <TableCell>{insights?.impressions}</TableCell>
                      <TableCell>{insights?.clicks}</TableCell>
                      <TableCell>
                        {insights?.conversionValueInLocalCurrency}
                      </TableCell>
                      <TableCell>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: dateRangeHtmlString
                          }}
                        ></div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default CampaignDataRow;
