import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import env from "react-dotenv";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import pusher from "../../utils/pusher";
import axios from "../../utils/axios";
import { useParams } from "react-router-dom";
// import WaiterComponent from "./general/WaiterComponent";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

export default function Summary({ ad_platform }) {
  const platformImage = require("../../assets/platform_icons/" +
    ad_platform +
    ".png");

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Item>
            <img src={platformImage} alt="meta image" height="50" width="50" />
            <Typography variant="div" component="div">
              {ad_platform}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={8}>
          <Item>
            <Typography variant="span" component="span"></Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
