import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";

const initialState = {
  campaigns: {
    grouped: {},
    all: [],
    filtered: {},
    selectedStatus: "all",
    loading: false,
    last_updated: "",
    refetch: false,
    is_campaigns_ranked: false,
    rankings: {
      all: []
    }
  },
  adsets: {
    filtered: {},
    selectedStatus: "all",
    loading: false,
    last_updated: "",
    refetch: false
  },
  ads: {
    filtered: {},
    selectedStatus: "all",
    loading: false,
    last_updated: "",
    refetch: false
  },
  last_updated: "",
  total_count: 0,
  loading: false
};

const snapchatCampaignSlice = createSlice({
  name: "snapchat",
  initialState,
  reducers: {
    addSnapchatCampaigns(state, action) {
      const allCampaigns = action.payload.all_campaigns;
      const pausedCampaigns = action.payload.paused_campaigns;
      const activeCampaigns = action.payload.active_campaigns;

      state["campaigns"]["filtered"]["all"] = {
        total_count: allCampaigns.length,
        data: allCampaigns
      };
      //   state["campaigns"]["filtered"]["active"] = {
      //     total_count: activeCampaigns.length,
      //     data: activeCampaigns
      //   };
      //   state["campaigns"]["filtered"]["paused"] = {
      //     total_count: pausedCampaigns.length,
      //     data: pausedCampaigns
      //   };
      state["campaigns"]["filtered"]["search"] = { total_count: 0, data: [] };
      state["campaigns"]["last_updated"] = moment().format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    changeCampaignStatusFilter(state, action) {
      const selectedFilter = action.payload;
      state["campaigns"]["selectedStatus"] = selectedFilter;
    },
    addGroupedCampaigns(state, action) {
      const groupedCampaigns = action.payload.grouped_campaigns;
      state["campaigns"]["grouped"] = groupedCampaigns;
    },
    searchLinkedinCampaigns(state, action) {
      const query = action.payload.toLowerCase();
      const currentStatusFilter = state["campaigns"]["selectedStatus"];
      const hayStack =
        state["campaigns"]["filtered"][currentStatusFilter]["data"];
      let searchResult = hayStack.filter((campaign) => {
        return campaign.name.toLowerCase().match(query);
      });
      state["campaigns"]["filtered"]["search"]["total_count"] =
        searchResult.length;
      state["campaigns"]["filtered"]["search"]["data"] = searchResult;
    },
    sortBySpendAmount(state) {
      const dataToSort = state["campaigns"]["filtered"]["search"]["data"];
      let sortedData = [];
      sortedData = dataToSort.sort((p1, p2) =>
        p1.spend_amount < p2.spend_amount
          ? 1
          : p1.spend_amount > p2.spend_amount
          ? -1
          : 0
      );
      state["campaigns"]["filtered"]["search"]["data"] = sortedData;
      state["campaigns"]["filtered"]["search"]["total_count"] =
        sortedData.length;
    },
    sortDataByDate(state, action) {
      const type = action.payload.type;
      let fromDate = action.payload.fromDate.trim();
      let toDate = action.payload.toDate.trim();
      const subjectId = action.payload.subjectId;

      const selectedFilter = state[type]["selectedStatus"];
      let dataToFilter = [];
      if (!subjectId) {
        dataToFilter = state[type]["filtered"]["all"]["data"];
      } else {
        dataToFilter = state[type]["filtered"][subjectId]["all"]["data"];
      }

      let filteredData = dataToFilter.filter((data) => {
        const start_time = moment(data?.start_time);
        fromDate = moment(fromDate, "DD-MM-YYYY");
        toDate = moment(toDate, "DD-MM-YYYY");
        return start_time.isBetween(fromDate, toDate);
      });

      _.set(state, `${type}.filtered.${subjectId}.search.data`, filteredData);
      // _.set(state, `${type}.filtered.${subjectId}.search.total_count`, filteredData.length)
      // state[type]["filtered"][subjectId]["search"]["data"] = filteredData
      // state[type]["filtered"][subjectId]["search"]["total_count"] = filteredData.length
    },
    changeLoadingStatus(state, action) {
      const type = action.payload.type;
      const status = action.payload.status;
      state[type]["loading"] = status;
    },
    addCampaignAdsets(state, action) {
      const adsetData = action.payload?.data;

      const campaignId = action.payload?.campaignId;
      _.set(state, "adsets.filtered." + campaignId, adsetData);
      _.set(
        state,
        "adsets.filtered." + campaignId + ".total_count",
        adsetData.length
      );

      const activeAdsets = adsetData.filter((adSet) => {
        return adSet.status == "ACTIVE";
      });
      const pausedAdsets = adsetData.filter((adSet) => {
        return adSet.status == "PAUSED";
      });
      _.set(state, "adsets.filtered." + campaignId + ".all.data", adsetData);
      _.set(
        state,
        "adsets.filtered." + campaignId + ".all.total_count",
        adsetData.length || 0
      );

      _.set(
        state,
        "adsets.filtered." + campaignId + ".paused.data",
        pausedAdsets
      );
      _.set(
        state,
        "adsets.filtered." + campaignId + ".paused.total_count",
        pausedAdsets.length || 0
      );

      _.set(
        state,
        "adsets.filtered." + campaignId + ".active.data",
        activeAdsets
      );
      _.set(
        state,
        "adsets.filtered." + campaignId + ".active.total_count",
        activeAdsets.length || 0
      );
      state["adsets"]["last_updated"] = moment().format("YYYY-MM-DD HH:mm:ss");
    },
    searchMetaAdsets(state, action) {
      const query = action.payload.toLowerCase();
      const currentStatusFilter = state["adsets"]["selectedStatus"];
      const hayStack = state["adsets"]["filtered"][currentStatusFilter]["data"];
      let searchResult = hayStack.filter((adset) => {
        return adset.name.toLowerCase().match(query);
      });
      state["adsets"]["filtered"]["search"]["total_count"] =
        searchResult.length;
      state["adsets"]["filtered"]["search"]["data"] = searchResult;
    },
    addAdsets(state, action) {
      const adSetDetails = action.payload?.data;
      const adsetId = action.payload?.adSetId;
      _.set(state, "ads.filtered." + adsetId + ".data", adSetDetails);
      _.set(
        state,
        "ads.filtered." + adsetId + ".total_count",
        adSetDetails.length
      );
      state["ads"]["last_updated"] = moment().format("YYYY-MM-DD HH:mm:ss");
    },
    addAds(state, action) {
      const ads = action.payload?.data;
      const adId = action.payload?.adId;
      _.set(state, "ads.filtered." + adId + ".data", ads);
      // _.set(state, "ads.filtered." + adId + ".total_count", ads.length);
      state["ads"]["last_updated"] = moment().format("YYYY-MM-DD HH:mm:ss");
    },

    searchMetaAds(state, action) {
      const query = action.payload.toLowerCase();
      const currentStatusFilter = state["ads"]["selectedStatus"];
      const hayStack = state["ads"]["filtered"][currentStatusFilter]["data"];
      let searchResult = hayStack.filter((ad) => {
        return ad.name.toLowerCase().match(query);
      });
      state["ads"]["filtered"]["search"]["total_count"] = searchResult.length;
      state["ads"]["filtered"]["search"]["data"] = searchResult;
    },
    changeAdSetStatusFilter(state, action) {
      const changedStatus = action.payload;
      state["adsets"]["selectedStatus"] = changedStatus;
    },
    changeLinkedInStatusFilter(state, action) {
      const changedStatus = action.payload.filter;
      const changeStatusMetaType = action.payload.type;
      state[changeStatusMetaType]["selectedStatus"] = changedStatus;
    },
    setRefetchStatus(state, action) {
      const type = action.payload.type;
      const status = action.payload.status;
      state[type]["refetch"] = status;
    },
    sortSnapchatCampaignsByRank(state, action) {
      const rankedCampaigns = action.payload;
      state["campaigns"]["rankings"]["all"] = rankedCampaigns;
      state["campaigns"]["is_campaigns_ranked"] = true;
      // _.set(state['campaigns']["rankings"]["total_count"],rankedCampaigns.length)
    }
  }
});

export const {
  addSnapchatCampaigns,
  changeCampaignStatusFilter,
  searchLinkedinCampaigns,
  sortBySpendAmount,
  changeLoadingStatus,
  addCampaignAdsets,
  addAdsets,
  addAds,
  changeAdSetStatusFilter,
  searchMetaAdsets,
  searchMetaAds,
  sortDataByDate,
  changeMetaStatusFilter,
  setRefetchStatus,
  sortSnapchatCampaignsByRank
} = snapchatCampaignSlice.actions;
export default snapchatCampaignSlice.reducer;
