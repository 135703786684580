import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";

const initialState = {
  account_metric_filters: {},
  campaigns: {}
};

const Metaslice = createSlice({
  name: "meta2",
  initialState,
  reducers: {
    addGroupedMetaCampaigns(state, action) {
      const { grouped_campaigns } = action.payload;
      state["grouped_campaigns"] = grouped_campaigns;
    }
  }
});

export const { addGroupedMetaCampaigns } = Metaslice.actions;

export default Metaslice.reducer;
