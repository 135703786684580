import React from "react";

function DataRowImg(props) {
  return (
    <>
      <div className="datarow-item">
        <div className="img-wrap">
          {props.date_range}
          <img src={props.logo} alt="" />
          <i>{props?.caption}</i>
        </div>
      </div>
    </>
  );
}

export default DataRowImg;
