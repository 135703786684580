import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { useFormik } from "formik";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { ErrorMessage } from "formik";
import appAxios from "../../utils/axios";

const supportedPlatforms = ["meta", "linkedin"];

export default function AddClient() {
  const validationSchema = Yup.object({
    name: Yup.string().min(3).required("Client name is required"),
    email: Yup.string().email().required("Email ID is required"),
    mobile: Yup.string().min(10).max(10).required("Mobile is required")
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      meta_account_id: "",
      linkedin_account_id: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const adPlatforms = [
          {
            name: "meta",
            account_id: values["meta_account_id"]
          },
          {
            name: "linkedin",
            account_id: values["linkedin_account_id"]
          }
        ];
        const { name, mobile, email } = values;
        const payload = { name, mobile, email, adPlatforms: [...adPlatforms] };

        const response = await appAxios.post("/clients/create", { ...payload });
      } catch (error) {
        ///handle
      }
    }
  });

  return (
    <Paper>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          error={formik.errors.name ? true : false}
          id="outlined-basic"
          label="Name"
          name="name"
          variant="outlined"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          helperText={formik.errors.name}
        />

        <TextField
          id="outlined-basic"
          label="Email"
          margin="normal"
          error={formik.errors.email ? true : false}
          variant="outlined"
          fullWidth
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={formik.errors.email}
        />
        <TextField
          id="outlined-basic"
          error={formik.errors.mobile ? true : false}
          label="Mobile"
          variant="outlined"
          fullWidth
          name="mobile"
          onBlur={formik.handleBlur}
          value={formik.values.mobile}
          onChange={formik.handleChange}
          helperText={formik.errors.mobile}
        />

        {supportedPlatforms.map((pl) => {
          return (
            <>
              <TextField
                id="outlined-basic"
                label={pl + " account id"}
                variant="outlined"
                fullWidth
                name={pl + "_account_id"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[pl + "_account_id"]}
                // helperText={formik.errors["account_ids"][pl]}
              />
              <br />
            </>
          );
        })}
        <Button type="submit" variant="contained">
          Add client
        </Button>
      </form>
    </Paper>
  );
}
