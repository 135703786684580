import * as React from "react";
import Box from "@mui/material/Box";
import appAxios from "../../utils/axios";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
export default function ListClients() {
  const [clientList, setClientList] = React.useState();
  const [error, setError] = React.useState("");

  const prepareClientList = async () => {
    try {
      const getClientListAPIUrl = `clients`;
      const getClientListResponse = await appAxios.get(getClientListAPIUrl);
      const clientListResponseData = getClientListResponse?.data?.data?.clients;
      setClientList(clientListResponseData);
    } catch (error) {}
  };

  React.useEffect(() => {
    prepareClientList();
  }, []);

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="secondary mailbox folders">
        <List>
          {clientList &&
            clientList.map((clientDetail) => {
              return (
                <ListItem disablePadding key={clientDetail.name}>
                  <ListItemButton>
                    <ListItemText primary={clientDetail.name} />
                    <Link to={`/clients/${clientDetail._id}`}>Details</Link>
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </nav>
    </Box>
  );
}
