
const symbols = {
    INR: '₹',
    USD:'$',
}

const getCurrencySymbol =function(name){
    return symbols[name]
}

exports.getCurrencySymbol=getCurrencySymbol
