import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { getCurrencySymbol } from "../../../utils/currencySymbols";

const AdsDataRow = ({ row }) => {
  console.log("PXC " + row);
  const spend_amount = row.spend_amount;
  const impressions = row?.impressions;
  const reaches = row?.reach;
  const startTime =
    moment(row?.date_start).format("DD-MM-YYYY HH:mm:ss") || "-";
  const endTime = moment(row?.date_stop).format("DD-MM-YYYY HH:mm:ss") || "-";
  const currency = row?.account_currency;
  const currencySymbol = getCurrencySymbol(getCurrencySymbol);
  const costPerClick = row?.cpc;
  const ctr = row?.ctr;
  const { clientId } = useParams();
  return (
    <TableRow key={row.id}>
      <TableCell>{row?.name || "-"}</TableCell>
      <TableCell>{row?.status || "-"}</TableCell>
      <TableCell>{impressions || "-"}</TableCell>
      <TableCell>{spend_amount || "-"}</TableCell>
      <TableCell>{costPerClick || "-"}</TableCell>
      <TableCell>{ctr || "-"}</TableCell>
      <TableCell>{reaches || "-"}</TableCell>
      <TableCell>{startTime || "-"}</TableCell>
      <TableCell>{endTime || "-"}</TableCell>
    </TableRow>
  );
};

export default AdsDataRow;
