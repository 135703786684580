import FacebookCampaignListReducer from "./facebook/CampaignListReducer";
import FacebookCampaignsReducer from "./facebook/facebookCampaignReducer";
import LinkedInCampaignReducer from "./linkedin/LinkedinCampaignReducer";
import MetaReducer from "./facebook/MetaReducer";
import CommonAppReducer from "./common/CommonReducer";
import { combineReducers } from "redux";
const rootReducer = combineReducers({
  meta: FacebookCampaignListReducer,
  linkedin: LinkedInCampaignReducer,
  common: CommonAppReducer,
  meta_campaigns: FacebookCampaignsReducer,
  meta2: MetaReducer
});
export default rootReducer;
