import React from "react";

function DataRowItem(props) {
  return (
    <>
      <div className="datarow-item">
        
        <div className="datarow-item__title">{props.title}</div>
        <div className="datarow-item__content">{props.content}</div>
        <div
          className={`card__description text-${
            props?.status ? props?.status : "danger"
          }`}
        >
          {props.report}
        </div>
      </div>
    </>
  );
}

export default DataRowItem;
