import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CustomDateRangePicker from "./DatePicker";

import {
  changeAccountPlatformFilter,
  changeReSearchFilter,
  changeSearchModalVisibility,
  changeRefetchingStatus
} from "../../../store/reducers/common/CommonReducer";
import { useDispatch, useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

export default function FilterModal({ isOpen }) {
  const dispatch = useDispatch();

  const isFilterModalActive = useSelector(
    (store) => store.common.account_metric_filters.is_filter_modal_active
  );
  const currentDateRange = useSelector(
    (store) => store.common.account_metric_filters.date_range
  );

  const handleClose = () => {
    dispatch(changeSearchModalVisibility(false));
  };

  const changeSearchFilterState = (e) => {
    dispatch(changeSearchModalVisibility(false));
    // dispatch(changeRefetchingStatus({ platform: "all", status: true }));
    dispatch(changeReSearchFilter({ status: true }));
  };
  return (
    <div>
      <Modal
        open={isFilterModalActive}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Platform</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="insights_start_date_input"
              label="Platform"
              defaultValue={"all"}
              onChange={(e) => {
                dispatch(
                  changeRefetchingStatus({
                    status: true,
                    platform: e.target.value
                  })
                );
                dispatch(
                  changeAccountPlatformFilter({ platform: e.target.value })
                );
              }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"meta"}>Meta</MenuItem>
              <MenuItem value={"google"}>Google</MenuItem>
              <MenuItem value={"linkedin"}>LinkedIn</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            Date Range
            <CustomDateRangePicker />
          </FormControl>
          <Button
            variant=""
            style={{ float: "right", marginLeft: "4px" }}
            variant="outlined"
            onClick={(e) => {
              dispatch(changeSearchModalVisibility(false));
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ float: "right" }}
            onClick={(e) => {
              changeSearchFilterState(e);
            }}
          >
            Filter
            {currentDateRange &&
              " " +
                currentDateRange.startDate +
                " to " +
                currentDateRange.endDate}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
