import _ from "lodash";

const filterCampaignData = (campaigns) => {
  const activeCampaigns = campaigns.filter((campaign) => {
    return campaign.status == "ACTIVE";
  });
  const pausedCampaigns = campaigns.filter((campaign) => {
    return campaign.status == "PAUSED";
  });

  return {
    all_campaigns: campaigns,
    paused_campaigns: pausedCampaigns,
    active_campaigns: activeCampaigns
  };
};

const rankCampaignsByParam = (campaigns, insightParam) => {
  const sortedList = _.sortBy(campaigns, insightParam);
  return sortedList;
};

const rankCampaigns = (campaigns, order = "desc", resultArrayCount = 10) => {
  const preparedCampaigns = campaigns.filter((campaignInsights) => {
    const id = campaignInsights?.campaign_id;
    const name = campaignInsights?.campaign_name;
    const spend = campaignInsights?.spend;
    const reach = campaignInsights?.reach;
    const impressions = campaignInsights?.impressions;
    const clicks = campaignInsights?.clicks;
    const cpc = campaignInsights?.cpc;
    const ctr = campaignInsights?.ctr;
    const conversions = campaignInsights?.conversions;
    const { date_start, date_stop } = campaignInsights;
    return {
      id,
      name,
      spend,
      reach,
      impressions,
      clicks,
      cpc,
      ctr,
      conversions,
      date_start,
      date_stop
    };
  });
  let sortCampaignList = _.sortBy(preparedCampaigns, "spend");
  if ((order = "asc")) {
    sortCampaignList = sortCampaignList.reverse().slice(0, resultArrayCount);
  } else {
    sortCampaignList = sortCampaignList.slice(0, resultArrayCount);
  }
  return sortCampaignList;
};
export { filterCampaignData, rankCampaigns };
