import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Charts(props) {
  const [currency, setCurrency] = useState("INR");

  const rankings = useSelector((state) => state.meta.campaigns?.rankings?.all);

  const [preparedRankings, setPreparedRankings] = useState(rankings);

  useEffect(() => {
    const tmp = [];
    if (rankings?.length > 0) {
      rankings.forEach((ranking) => {
        tmp.push({
          name: ranking?.name,
          spend: ranking?.spend,
          impressions: ranking?.impressions,
          reach: ranking?.reach,
          clicks: ranking?.clicks,
          cpc: ranking?.cpc,
          ctr: ranking?.ctr
        });
      });
      setPreparedRankings(tmp);
    }
  }, [rankings]);

  const [maxYAxisValue, setMaxYAxisValue] = useState();

  const options = {
    responsive: true,
    normalized: true,
    animation: false,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: "Campaign statistics - ACTIVE ONLY"
      },
      tooltip: {}
    }
  };

  const labels = [];
  const datasets = [];
  preparedRankings.forEach((ranking) => {
    labels.push(ranking?.name);
  });
  datasets.push(
    {
      label: "Amount spend",
      data: preparedRankings.map((ranking) => ranking?.spend),
      backgroundColor: "rgba(255, 99, 132, 0.5)"
    },
    {
      label: "Impressions",
      data: preparedRankings.map((ranking) => ranking?.impressions),
      backgroundColor: "rgba(53, 162, 235, 0.5)"
    },
    {
      label: "Cost per click",
      data: preparedRankings.map((ranking) => ranking?.cpc),
      backgroundColor: "rgba(53, 234,0 , 0.5)"
    },
    {
      label: "CTR",
      data: preparedRankings.map((ranking) => ranking?.ctr),
      backgroundColor: "rgba(0, 10, 20, 0.5)"
    }
  );
  const data = {
    labels,
    datasets
  };

  return <Bar type="bar" data={data} options={options} />;
}
